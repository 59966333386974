import React from "react";
import { graphql, Link } from "gatsby";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
// import ReactHtmlParser from "react-html-parser";
import { PropTypes } from "prop-types";
import Layout from "../components/layout";
import ReactHtmlParser from "react-html-parser";
import SEO from "../components/seo";
import moment from "moment";
// import Img from "gatsby-image";

const News = ({ data, location }) => {
  const posts = data.wpgraphql.posts.nodes
  console.log(posts)
  return (
    <Layout location={location}>
      <SEO
        keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
        title="News"
      />
      <ul style={{ listStyle: "none" }}>
        {
          posts.map( (node) => {
            console.log(node)
            return (
              <section key={`post-${node.slug}`} className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
                <li>
                  <Link
                  to={`/news/${node.slug}`}
                  >
                    <h3 className="text-white text-white font-title font-thin text-4xl mb-5">
                      {node.title}
                    </h3>
                    <p className="text-white font-body md:w-5/6 sm:w-full">
                      {moment(node.date).format("MMMM Do YYYY")}
                    </p>
                    <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(node.excerpt) }</div>
                  </Link>
                </li>
              </section>
            )
          })
        }
      </ul>
    </Layout>
  )
}

News.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default News;

export const query = graphql`
query {
  wpgraphql {
    posts {
      nodes {
        featuredImage {
          sourceUrl
          altText
          mediaType
          uri
          title
          mediaItemUrl
          sizes
          srcSet
        }
        date
        uri
        slug
        excerpt
        title
      }
    }
  }
}
`